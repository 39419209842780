export const counties: County[] =
    [
        {id: '01', name: 'budapest'},
        {id: '02', name: 'baranya'},
        {id: '03', name: 'bacs'},
        {id: '04', name: 'bekes'},
        {id: '05', name: 'borsod'},
        {id: '06', name: 'csongrad'},
        {id: '07', name: 'fejer'},
        {id: '08', name: 'gyor'},
        {id: '09', name: 'hajdu'},
        {id: '10', name: 'heves'},
        {id: '11', name: 'komarom'},
        {id: '12', name: 'nograd'},
        {id: '13', name: 'pest'},
        {id: '14', name: 'somogy'},
        {id: '15', name: 'szabolcs'},
        {id: '16', name: 'jasz'},
        {id: '17', name: 'tolna'},
        {id: '18', name: 'vas'},
        {id: '19', name: 'veszprem'},
        {id: '20', name: 'zala'}
    ];

export interface County {
    id: string;
    name: string;
}
