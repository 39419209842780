

import {Component, Vue} from 'vue-property-decorator';
import CourtService from '../service/court-service';
import {counties, County} from "../common/county";
import CustomPageTitle from "../components/CustomPageTitle.vue";

@Component({
    components: {
        CustomPageTitle
    }
})
export default class CourtOperation extends Vue {
    public counties: County[] = counties;
    public countyStatuses: any = {};

    private courtService = new CourtService();

    public findStatus(rawId: string) {
        const id = parseInt(rawId, 10);
        if (this.countyStatuses[id]) {
            return this.countyStatuses[id].state;
        }
        return '?';
    }

    public mounted() {
        this.courtService.retrieveCourtStatuses()
            .then((response) => {
                    this.countyStatuses = response.data;
                },
            );
    }

}
